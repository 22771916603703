var OrderForm = {
  init: function() {
    this.assign_event_handlers();

    var currentGradeField = $('select#order_current_grade');

    if (currentGradeField.length > 0) {
      currentGradeField.change();
    } else {
      if ($('.submitted-line-item').length < 1) {
        $('.order-form #add-another-product-button').click();
      }
    }
  },
  assign_event_handlers: function() {
    $('select#order_current_grade').change(function() {
      var currrentGrade = $(this).val();
      var yoji = $(this).data('yoji');

      $('.order-form #order-items').html('');
      setTimeout(function() {
        $.get('/next_grade?current_grade=' + currrentGrade + '&yoji=' + yoji, function(data) {
          data.map(function(item) {
            if (!$('option[selected="selected"][value="' + item['product_id'] + '"]').attr('selected')) {
              OrderForm.add_another_product(item['product_id']);
            }
          });
        });
      }, 500);
    });

    $(document).on('click', 'a.delete-item', function(e) {
      e.preventDefault();

      var parent = $(this).parent().parent();

      parent.remove();
      OrderForm.update_total_price();

      return false;
    });

    $('.order-form #add-another-product-button').click(function() {
      OrderForm.add_another_product(null);
    });

    $('.order-form').change('select#order_line_items__product_id', function(event) {
      if ($(event.target).attr('id') == 'order_line_items__product_id') {
        OrderForm.product_selected($(event.target));
      }
    });

    $('.order-form').change('input#order_line_items__quantity, input#order_line_items__unit_price', function() {
      OrderForm.update_total_price();
    });

    $('.order-form input[type="submit"][value="Ordered AND Delivered"]').click(function(event) {
      OrderForm.warn_if_delivered_but_unpaid(event);
    });

    $(document).on('click', '.cancel-order', function(e) {
      e.preventDefault()
      $('#cancel-options').attr('data-ref', $(this).attr('data-redir'))
      $('#cancel-options').modal('show')
    })

    $(document).on('click', '#refund-yes', function(e) {
      OrderForm.cancel_option(true)
    })

    $(document).on('click', '#refund-no', function(e) {
      OrderForm.cancel_option(false)
    })
  },
  cancel_option: function(refunded) {
    var url = $('#cancel-options').attr('data-ref')

    $.ajax({
      method: 'PATCH',
      url: url,
      data: {
        refund: refunded
      }
    })
  },
  add_another_product: function(selected) {
    var $line_item_fields_template_container = $('.order-form #order-line-item-fields-template-container');
    var $line_item_fields_template_main_container = $('.order-form #order-items');
    var $new_line_item_fields = $line_item_fields_template_container.clone().removeAttr('id').removeClass('d-none');
    var $new_line_item_product_field = $new_line_item_fields.find('#order_line_items__product_id').addClass('selectpicker');
    $line_item_fields_template_main_container.append($new_line_item_fields);

    $new_line_item_fields.find('select').val(selected);
    SelectPicker.init($new_line_item_product_field);
    // trigger item price update and total
    $new_line_item_product_field.change();
  },
  product_selected: function($select) {
    $unit_price = $select.parents('.form-row').find('input#order_line_items__unit_price');
    selected_option = $select.find('option:selected');
    selected_product_label = selected_option.text()
    selected_category = selected_option.parent().attr('label')
    product_name = ''

    if (selected_product_label !== '') {
      unit_price_match_results = selected_product_label.match(/\([\$£](\d+\.\d+)\)/);
      if (unit_price_match_results && unit_price_match_results.length > 0) {
        product_name = selected_product_label.replace('($' + unit_price_match_results[1] + ')', '')
        $unit_price.val(unit_price_match_results[1]);
      }
    }

    if ($select.data('graded') == false && product_name != '' && ['Belts', 'Grading - Yoji'].includes(selected_category) && $('.order-form').data('canchange') == true) {
      OrderForm.lookup_pair_products($select.data('yoji'), product_name.trim(), selected_category)
    }
  },
  update_total_price: function() {
    $total_price = $('.order-form #order_total_price');
    total_price = 0;
    $('.order-form .form-row:visible').each(function() {
      quantity = $(this).find('input#order_line_items__quantity').val();
      unit_price = $(this).find('input#order_line_items__unit_price').val();

      if ((quantity.trim() !== '') && (unit_price.trim() !== '')) {
        quantity = parseInt(quantity);
        unit_price = parseFloat(unit_price);
        total_price += (quantity * unit_price);
      }
    });
    if (total_price < 0) {
      total_price = 0;
    }
    $total_price.val(total_price.toFixed(2));
  },
  warn_if_delivered_but_unpaid: function(event) {
    // If form is submitted by the Ordered AND Delivered button, but the Paid? checkbox is unticked,
    // confirm the submission via a popup. If the user cancels, stop the form submit event.
    $paid_checkbox = $('.order-form input[type="checkbox"][name="order[paid]"]');
    if (!$paid_checkbox[0].checked) {
      if (!window.confirm('You are marking this purchase as delivered, but unpaid. Are you sure you want to proceed?')) {
        event.preventDefault();
      }
    }
  },
  lookup_pair_products: function(yoji, product_name, category) {
    var url = '/pair_lookup.js'

    $.ajax({
      method: 'GET',
      url: url,
      data: {
        yoji: yoji,
        product_name: product_name,
        category: category
      }
    }).done( function(data) {
      if (data.id != null) {
        $('.order-form').data('canchange', false)
        OrderForm.add_another_product(data.id)
        setTimeout(function() {
          $('.order-form').data('canchange', true)
        }, 500)
      }
    })
  }
};

document.addEventListener("turbolinks:load", () => {
  if ($('.order-form')[0] || ($('.order-list').length > 0) || ($('#stock-order-list').length > 0) || ($('#stock-orders').length > 0)) {
    OrderForm.init();
  }
});
