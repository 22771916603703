document.addEventListener("turbolinks:load", () => {

  $('.manage-request-option-item').on('click', function(e) {
    var option = $(this).text()
    $('.session-action').prop('disabled', false)
    $('.manage-request-option-item').removeClass('active')
    $(this).addClass('active')
    $('.session-action').attr('data-action', option)
  })

  $('.session-action').on('click', function(e) {
    var option = $(this).attr('data-action')
    var id = $(this).attr('data-id')
    var url = '/events/' + id + '/cancel_destroy'
    var call_type = 'GET'
    var confirm_msg = 'Are you sure you want to cancel the request to delete this session?'

    if (option == 'Delete session') {
      url = '/events/' + id
      call_type = 'DELETE'
      confirm_msg = 'Deleting a session will remove all student records and stock sales related to that session. Do you wish to proceed?'
    }

    if (confirm(confirm_msg)) {
      $.ajax({
        url: url,
        type: call_type,
        dataType: 'script'
      })
    } else {
      e.preventDefault()
    }
  })

  $('.delete-session-option-item').on('click', function(e) {
    var answer = $(this).text()

    $('.delete-session-option-item').removeClass('active')
    $(this).addClass('active')

    $('input[name=survey_answer]').val(answer)
    $('.submit-request').prop('disabled', false)

    if (answer == 'Other') {
      $('.delete-session-other-option').removeClass('hidden')
    } else {
      if (!$('.delete-session-other-option').hasClass('hidden')) {
        $('.delete-session-other-option').addClass('hidden')
      }
    }

    e.preventDefault()
  })

  $('.other-info').on('blur', function() {
    var other_info = $(this).val()

    $('input[name=other_info]').val(other_info)
    if (other_info != '') {
      $('span.error-message').html('')
    }
  })

  $('form.delete-session-frm').on('submit', function(e) {
    var answer = $('input[name=survey_answer]').val()
    var other_info = $('input[name=other_info]').val()

    if (answer == 'Other') {
      if (other_info == '') {
        $('span.error-message').html('Other reason is required.')
        e.preventDefault()
      } else {
        $('span.error-message').html('')
      }
    }

    if (confirm('Deleting a session will remove all student records and stock sales related to that session. Do you wish to proceed?')) {
      // proceed
    } else {
      e.preventDefault()
    }
  })

  $('.event-select-all-students').on('click', function() {
    $(this).parent('form').find('input[type=checkbox]:not([disabled])').each(function () {
      this.checked = !this.checked
    })
  })
})
