var StudentRegistrationForm = {
  init: function() {
    this.assign_event_handlers();
    this.init_main_contact_lookup_autocomplete();
    this.init_student_places_autocomplete();
  },
  assign_event_handlers: function() {
    $(".admin-student-registration-form input[name='student[use_existing_main_contact]']").change(function() {
      StudentRegistrationForm.toggle_use_existing_main_contact();
    }).trigger('change');

    $(".admin-student-registration-form input[name='student[main_contact_lookup]']").keyup(function() {
      this.value = this.value.replace(/\s/g, '');
    });

    $(".admin-student-registration-form").on('change', "input[name*='birth_date']", function() {
      StudentRegistrationForm.check_birth_date_validity($(this));
    });

    $(".admin-student-registration-form button#add-another-student-button").click(function() {
      StudentRegistrationForm.add_another_student();
    });

    $(document).on('click', '.register-on-behalf', function(e) {
      if ($(this)[0].checked) {
        $('.member-not-present').removeClass('hidden')
        $('.account-sig').addClass('hidden')
        $('#next-option').prop('disabled', false)
      } else {
        $('.member-not-present').addClass('hidden')
        $('#student_contract_unsigned').prop('checked', false)
        $('.account-sig').removeClass('hidden')
        if ($('#student_account_holder_signature').val() == '') {
          $('#next-option').prop('disabled', true)
        } else {
          $('#next-option').prop('disabled', false)
        }
      }
    })

    $(document).on('focusout', ".admin-student-registration-form input[name='student[main_contact_email]']", function (e) {
      $('.student-email').val($(this).val())
    })

    $(document).on('focusout', ".admin-student-registration-form input[name='student[main_contact_mobile_phone]']", function (e) {
      $('.student-phone').val($(this).val())
    })

    $(document).on('change', '#payment-method', function() {
      let rate = 0

      if (($(this).val() == '') || ($(this).val() == 'None')) {
        $('.payment-required').addClass('hidden')
        $('#save-option').removeClass('hidden')
        $('#next-option').prop('disabled', false)
      } else {
        $('.payment-required').removeClass('hidden')
        // $('#next-option').prop('disabled', false)
        $('#save-option').addClass('hidden')

        rate = parseFloat($('#student_weekly_training_fee_amount').val())
        if ( rate > 0) {
          StudentRegistrationForm.compute_total_instalment_amount(rate)
        }
        StudentRegistrationForm.prep_tos_content()
      }
    })

    $(document).on('change', '.pass-type', function() {
      StudentRegistrationForm.prep_tos_content()
    })

    $(document).on('change', '#student_training_frequency', function(e) {
      if ($(this).val() == 'Mixed') {
        $('#student_mixed_frequency_details').removeClass('hidden')
        $('.mixed-info').removeClass('hidden')
      } else {
        $('#student_mixed_frequency_details').addClass('hidden')
        $('.mixed-info').addClass('hidden')
      }
    })

    $(document).on('blur', '#student_weekly_training_fee_amount', function(e) {
      let rate = parseFloat($(this).val())
      if ( rate > 0) {
        StudentRegistrationForm.compute_total_instalment_amount(rate)
      }
    })

    $(document).on('blur', '#student_account_setup_fee', function(e) {
      let errorElement = $('#admin-fee-notice')
      let admin_fee = parseFloat($(this).val())

      $(errorElement).text('')
      $.ajax({
        method: 'GET',
        url: '/admin/students/admin_fee.json',
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        data: {
          admin_fee: admin_fee
        },
        success: function(data) {
          if (data.status == 'error') {
            e.target.setCustomValidity(data.message)
            $(errorElement).text(data.message)
          } else {
            e.target.setCustomValidity('')
          }
        }
      })
    })

    $(document).on('change', '#student_payment_frequency', function(e) {
      let rate = parseFloat($('#student_weekly_training_fee_amount').val())
      if ( rate > 0) {
        StudentRegistrationForm.compute_total_instalment_amount(rate)
      }
    })

    $(document).on('click', '.place-details', function(e) {
      e.preventDefault()
      $('.location-details').toggleClass('hidden')
    })

    $(document).on('click', '.same-account-holder', function(e) {
      let studentFirstName = $('.student-first-name')[0]
      let studentLastName = $('.student-last-name')[0]

      $(studentFirstName).val($(".admin-student-registration-form input[name='student[main_contact_first_name]']").val())
      $(studentLastName).val($(".admin-student-registration-form input[name='student[main_contact_last_name]']").val())
    })

    $(document).on('blur', '.student-dob', function(e) {
      let dob = new Date($(this).val())
      let passType = $(this).attr('id').split('_')[2]

      if (StudentRegistrationForm.exactAge(dob).years > 3) {
        $('#student_students_' + passType + '_pass_type').val('Karate - Regular')
      } else {
        $('#student_students_' + passType + '_pass_type').val('Karate - Yoji')
      }
    })

    $(document).on('change', '#student_joining_fee', function(e) {
      let joiningFee = $(this).val()

      if (parseFloat(joiningFee) > 0) {
        $('.joining-fee-required').toggleClass('hidden')
        $('#student_joining_fee_payment_date').attr('required', true)
      } else {
        $('#student_joining_fee_payment_date').attr('required', false)
      }
    })

    // check if we need to show the 
    if ($('#tos-content').length > 0) {
      StudentRegistrationForm.prep_tos_content()
    }
  },
  exactAge(birthdate) {
    // https://www.kevinleary.net/blog/javascript-age-birthdate-mm-dd-yyyy/
    let startDate = new Date(new Date(birthdate).toISOString().substr(0, 10));
    const endingDate = new Date().toISOString().substr(0, 10); // YYYY-MM-DD
    let endDate = new Date(endingDate);
    if (startDate > endDate) {
      const swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    const startYear = startDate.getFullYear();

    // Leap years
    const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    let dayDiff = endDate.getDate() - startDate.getDate();

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return {
      years: yearDiff,
      months: monthDiff,
      days: dayDiff,
    };
  },
  compute_total_instalment_amount: function(rate) {
    let amt = 0

    switch($('#student_payment_frequency').val()) {
      case 'Monthly':
        amt = (parseFloat(rate) * 52) / 12
        break
      case '4 Weekly':
        amt = parseFloat(rate) * 4
        break
      case 'Fortnightly':
        amt = parseFloat(rate) * 2
        break
      case 'Weekly':
        amt = parseFloat(rate)
        break
    }

    $('#student_total_instalment_amount').val(amt.toFixed(2))
  },
  prep_tos_content: function() {
    let country = $(".admin-student-registration-form input[name='student[country]']").val()

    if (country != '') {
      let data = {
        country: $(".admin-student-registration-form input[name='student[country]']").val(),
        payment_method: $('#payment-method').val(),
        yoji: StudentRegistrationForm.with_yoji()
      }
      // check if we have a value
      if ((data.country !== null) && (data.payment_method !== null) && (data.yoji !== null)) {
        StudentRegistrationForm.load_tos(data)
      }
    }
  },
  init_main_contact_lookup_autocomplete: function() {
    $(".admin-student-registration-form input[name='student[main_contact_lookup]']").autocomplete({
      serviceUrl: '/admin/students',
      dataType: 'json',
      minChars: 3,
      formatResult: function(suggestion, currentValue) {
        return suggestion.value.replace(currentValue, ('<strong>' + currentValue + '</strong>'));
      },
      onSelect: function(suggestion) {
        StudentRegistrationForm.populate_from_autocomplete_data(suggestion.data);
      }
    });
  },
  init_student_places_autocomplete: function() {
    $(".admin-student-registration-form input[name='student[place_lookup]']").autocomplete({
      serviceUrl: '/admin/students/places',
      dataType: 'json',
      minChars: 3,
      formatResult: function(suggestion, currentValue) {
        return suggestion.value.replace(currentValue, ('<strong>' + currentValue + '</strong>'));
      },
      onSelect: function(suggestion) {
        StudentRegistrationForm.populate_location_details(suggestion.data);
      }
    });
  },
  toggle_use_existing_main_contact: function() {
    var $use_existing_main_contact = $(".admin-student-registration-form input[type='checkbox'][name='student[use_existing_main_contact]']");
    var $main_contact_lookup = $(".admin-student-registration-form input[name='student[main_contact_lookup]']");
    var $main_contact_name = $(".admin-student-registration-form input[name='student[main_contact_name]']");

    if ($use_existing_main_contact[0].checked) {
      // Search for and use an existing main contact.
      $main_contact_lookup.prop('disabled', false);
      $main_contact_name.prop('disabled', true);
      $('.main-contact-lookup-wrapper').removeClass('hidden')
      $main_contact_lookup.attr('required', true);
      $main_contact_lookup.focus();
    }
    else {
      // Create a new main contact.
      $main_contact_lookup.prop('disabled', true);
      $main_contact_name.prop('disabled', false);
      $('.main-contact-lookup-wrapper').addClass('hidden')
      $main_contact_lookup.attr('required', false);
      $main_contact_name.focus();
    }
  },
  with_yoji: function() {
    let yoji = false
    let passTypes = document.querySelectorAll('.pass-type')
    passTypes.forEach( (passtype) => {
      if (passtype.value == 'Karate - Yoji') {
        yoji = true
      }
    })
    return yoji
  },
  load_tos: function(data) {
    $.ajax({
      method: 'GET',
      url: '/admin/students/tos.json',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: {
        country: data.country,
        payment_method: data.payment_method,
        yoji: data.yoji
      },
      success: function(data) {
        let tos_content = ''
        tos_content += '<p class="mb-1">' + data.content.terms + '</p>'
        if (data.content.yoji != null) {
          tos_content += '<p class="mb-1">' + data.content.yoji + '</p>'
        }
        $('#tos-content').html(tos_content)
      }
    })
  },
  populate_location_details: function(data) {
    let notice = ''

    $('#location-notice').text('')
    $.ajax({
      method: 'GET',
      url: '/admin/students/place.json',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: {
        place_id: data.place_id
      },
      success: function(data) {
        $(".admin-student-registration-form input[name='student[street_address]']").val('');
        $(".admin-student-registration-form input[name='student[city_suburb]']").val('');
        $(".admin-student-registration-form input[name='student[state]']").val('');
        $(".admin-student-registration-form input[name='student[postcode]']").val('');

        // for NZ, street is required
        if (data.place.country == 'New Zealand') {
          if ((data.place.street == '') || (data.place.street == null)) {
            notice = 'Street address is required. Please search again.'
          }
          $(".admin-student-registration-form input[name='student[country]']").prop('required', true)
        } else {
          $(".admin-student-registration-form input[name='student[country]']").prop('required', false)
        }

        if ((data.place.city == null) || (data.place.city == '')) {
          notice = 'City/Suburb is required. Please search again.'
        }

        if ((data.place.postal_code == null) || (data.place.postal_code == '')) {
          notice = 'Postal code is required. Please search again.'
        }

        if (notice == '') {
          $(".admin-student-registration-form input[name='student[street_address]']").val(data.place.street);
          $(".admin-student-registration-form input[name='student[city_suburb]']").val(data.place.city);
          $(".admin-student-registration-form input[name='student[state]']").val(data.place.state_region);
          $(".admin-student-registration-form input[name='student[postcode]']").val(data.place.postal_code);
        } else {
          $('#location-notice').text(notice)
        }
      }
    })
  },
  populate_from_autocomplete_data: function(data) {
    $(".admin-student-registration-form input[name='student[main_contact_sfid]']").val(data.main_contact_sfid);
    $(".admin-student-registration-form input[name='student[main_contact_mobile_phone]']").val(data.mobile_phone);
    $(".admin-student-registration-form input[name='student[main_contact_email]']").val(data.email);

    $(".admin-student-registration-form input[name='student[street_address]']").val(data.street_address);
    $(".admin-student-registration-form input[name='student[city_suburb]']").val(data.city_suburb);
    $(".admin-student-registration-form input[name='student[state]']").val(data.state);
    $(".admin-student-registration-form input[name='student[postcode]']").val(data.postcode);
    $(".admin-student-registration-form select[name='student[country]']").val(data.country);

    $(".admin-student-registration-form input[name='student[main_contact_first_name]']").val(data.first_name);
    $(".admin-student-registration-form input[name='student[main_contact_last_name]']").val(data.last_name);

    // set the student email and phone, this could be a problem if there were
    // multiple students entered before picking the existing main contact
    $('.student-email').val(data.email)
    $('.student-phone').val(data.mobile_phone)

    if ((data.street_address != null) && (data.city_suburb != null)) {
      $('#student_place_lookup').prop('required', false)
    } else {
      $('#student_place_lookup').prop('required', true)
    }
  },
  check_birth_date_validity: function($birth_date) {
    $birth_date_warning = $birth_date.siblings('.invalid-feedback');

    var selected_birth_date = Date.parse($birth_date.val());

    var now = new Date();
    var three_years_ago = now.setFullYear(now.getFullYear() - 3);

    if (selected_birth_date > three_years_ago) {
      $birth_date_warning.show().text('Is this date correct? Is the student less than 3 years old?');
    }
    else {
      $birth_date_warning.hide()
    }
  },
  add_another_student: function() {
    var student_details_fields_count = $(".admin-student-registration-form #cloneable-student-details-fields").length;

    var $cloneable_student_details_fields = $(".admin-student-registration-form #cloneable-student-details-fields:last");

    var cloneable_student_details_id = $cloneable_student_details_fields.find("input[type='hidden']").val();
    var cloned_student_details_id = 'new-student-details-' + (student_details_fields_count + 1);

    // Make a new copy of the div of fields.
    var $cloned_new_student_details_fields = $cloneable_student_details_fields.clone(true);

    // Replace the ID (used in input names, ids, etc) with the new one.
    $cloned_new_student_details_fields.html($cloned_new_student_details_fields.html().replace(new RegExp(cloneable_student_details_id, 'g'), cloned_student_details_id));

    // Set the header.
    $cloned_new_student_details_fields.find('h2').text('Details for Student ' + (student_details_fields_count + 1));

    // Clear inputs (except for the dropdown list, and the hidden one which holds the ID).
    $cloned_new_student_details_fields.find('input').not("[type='hidden']").not("[type='radio']").val('');
    $cloned_new_student_details_fields.find("input[type='radio']").prop('checked', false);
    $cloned_new_student_details_fields.find('.invalid-feedback').hide();

    // Set initial email and phone number
    $cloned_new_student_details_fields.find('.student-email').val($(".admin-student-registration-form input[name='student[main_contact_email]").val())
    $cloned_new_student_details_fields.find('.student-phone').val($(".admin-student-registration-form input[name='student[main_contact_mobile_phone]").val())
    $cloned_new_student_details_fields.find('.student-membership-offer').val($(".admin-student-registration-form .student-membership-offer").val())
    $cloned_new_student_details_fields.find('.student-fcd').val($(".admin-student-registration-form .student-fcd").val())

    $cloned_new_student_details_fields
      .find('#remove-student-button')
      .show()
      .on('click', function(e) {
        StudentRegistrationForm.remove_student(e);
      });

    $cloned_new_student_details_fields.insertAfter($cloneable_student_details_fields);

    $('#student_training_frequency').append('<option class="student-training-freq-mixed" value="Mixed">Mixed</option>');
  },
  remove_student: function(e) {
    e.target.parentElement.remove();
    if ($(".admin-student-registration-form #cloneable-student-details-fields").length == 1) {
      $("option[class='student-training-freq-mixed']").remove();
    }
  }
};

document.addEventListener("turbolinks:load", () => {
  if ($('.admin-student-registration-form')[0]) {
    StudentRegistrationForm.init();
  }
});
