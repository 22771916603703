import { BrowserQRCodeReader } from '@zxing/library';

var QRScanner = {
  // let codeReader = new BrowserQRCodeReader()

  init: function() {
    this.capture()
  },
  capture: function() {
    const codeReader = new BrowserQRCodeReader()

    this.show_scan_response()

    codeReader.getVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length >= 1) {
          codeReader.decodeFromInputVideoDeviceContinuously(undefined, 'qr-code-capture', (result, err) => {
            if (result) {
              var $qr_code_form = $('form#qr-code-form')
              var current_qr_code = $qr_code_form.find("input[name='qr_code[contents]']").val()

              if (current_qr_code != result.text) {
                this.send_code(result.text)
                this.recapture()
              }
            }
          })
        }
      })
      .catch((err) => {})
  },
  send_code: function(qr_code) {
    var $qr_code_form = $('form#qr-code-form')

    this.collectEventIds()
    $qr_code_form.find("input[name='qr_code[contents]']").val(qr_code)
    $qr_code_form.submit()
  },
  show_scan_response: function() {
    var scan_responder = document.getElementById('scan-response')
    if ($('.alert-success').length > 0) {
      if ((scan_responder.style.display == '') || (scan_responder.style.display == 'none')) {
        scan_responder.style.display = 'block'
        setTimeout(() => {
          scan_responder.style.display = ''
        }, "2000")
      }
    }
  },
  recapture: function() {
    this.capture()
  },
  collectEventIds: function() {
    let event_ids = []

    $('.event-options').each( function(elm) {
      if ($(this).hasClass('btn-danger')) {
        event_ids.push($(this).attr('data-id'))
      }
    })

    if (event_ids.length > 0) {
      $('#qr_code_event_ids').val(event_ids)
    }
  }
}

document.addEventListener("turbolinks:load", () => {
  if ($('video#qr-code-capture')[0]) {
    QRScanner.init()
  }
})
