var SendPassViaSMS = {
  init: function() {
    this.assign_event_handlers()
  },
  assign_event_handlers: function() {

    $('#send-update-link').click(function(e) {
      var attend_payload = $('#sms-pass-account').serialize()
      var event_id = $(this).attr('data-event-id')
      var student_id = $(this).attr('data-student_id')
      var phone = $('#phone_number').val()
      var country = $('#country').val()
      var formatted_phone = valid_phone_number(phone, country)
      var url = ''

      if ($(this).hasClass('disabled')) {
        return false
      } else {
        $(this).addClass('disabled')
      }

      if ($(this).attr('data-call-from') == 'add-student') {
        url = '/events/' + event_id + '/attend/' + student_id
      } else {
        url = '/events/' + event_id + '/send_pass'
      }

      e.preventDefault()
      if ((phone != '') && (formatted_phone != '')) {
        for (index = 0; index < attend_payload.length; ++index) {
          if (attend_payload[index].name == "phone_number") {
            attend_payload[index].value = formatted_phone
            break
          }
        }

        $.ajax({
          url: url,
          type: 'PATCH',
          data: attend_payload
        }).done( function() {})
      } else {
        $('.error-message').html('Please enter a valid mobile phone number.')
        $(this).removeClass('disabled')
      }

      function valid_phone_number(phone, country) {
        var raw_phone = phone.replace(/\s/g, '')
        var country_codes = ['61', '44', '64']
        var code_prefix = raw_phone.slice(0, 2)

        if (country_codes.includes(code_prefix)) {
          raw_phone = raw_phone.replace(code_prefix, '0')
        }

        switch(country) {
          case 'Australia':
            raw_phone = '61' + raw_phone
            break
          case 'New Zealand':
            raw_phone = '64' + raw_phone
            break
          case 'United Kingdom':
            raw_phone = '44' + raw_phone
            break
        }

        // plus 2 digits, for the country code
        if ((raw_phone.length >= 10) && (raw_phone.length <= 14)) {
          return raw_phone
        } else {
          return ''
        }
      }
    })

    $('#send-pass-option').click(function(e) {
      $('#send-pass-options').modal('show')
      e.preventDefault()
    })

    $('#send-pass-via-email').click(function(e) {
      var student_id = $(this).attr('data-student-id')

      $('#send-pass-from-account').modal('show')
      $('#send-pass-sms-account').attr('data-student_id', student_id)
      e.preventDefault()
    })

    $('#send-pass-via-sms').click(function(e) {
      var student_id = $(this).attr('data-student-id')

      if ($(this).attr('data-call-from') == 'account') {
        $('#send-pass-sms-account').html('Send')
      }
      $('#send-pass-from-account').modal('show')
      $('#send-pass-sms-account').attr('data-student_id', student_id)
      e.preventDefault()
    })

    $('#phone_number').keypress(function(e) {
      var ASCIICode = (e.which) ? e.which : e.keyCode
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
          return false;
      return true;
    })

    $('#send-pass-sms-account').on('click', function(e) {
      var attend_payload = $('#sms-pass-account').serialize()
      var event_id = $(this).attr('data-event-id')
      var student_id = $(this).attr('data-student_id')
      var via_email = $(this).attr('data-via-email')
      var url = ''

      if ($(this).hasClass('disabled')) {
        return false
      } else {
        $(this).addClass('disabled')
      }

      if (via_email == 'true') {
        url = '/admin/students/' + $('#student_id').val() + '/send_pass'
      } else {
        if ($(this).attr('data-call-from') == 'add-student') {
          url = '/events/' + event_id + '/attend/' + student_id
        } else {
          url = '/events/' + event_id + '/send_pass'
        }
      }

      e.preventDefault()
      $.ajax({
        url: url,
        type: 'PATCH',
        data: attend_payload
      }).done( function() {})
    })

    $('.add-student').click(function(e) {
      var student_id = $(this).attr('data-student-id')
      var event_id = $(this).attr('data-event-id')
      var form_payload = {
        event_ids: $(this).attr('data-event-ids'),
        location: $(this).attr('data-location'),
        date: $(this).attr('data-date'),
        region: $(this).attr('data-region')
      }
      var mobile = $(this).attr('data-mobile')

      if ($('#send-pass-from-account').length > 0) {
        var send_via_sms = $('#ssp-' + student_id).is(':checked')
        if (send_via_sms) {
          $('#send-pass-from-account').modal('show')
          $('#send-pass-sms-account').attr('data-student_id', student_id)
          $('#send-pass-sms-account').attr('data-call-from', 'add-student')
          $('#send-update-link').attr('data-call-from', 'add-student')
          $('#sms-pass-account #student_id').val(student_id)
          $('#masked-mobile').html(mobile)
          e.preventDefault()
        } else {
          // send_payload(event_id, student_id, $('#sms-pass-account').serialize())
          send_payload(event_id, student_id, form_payload)
        }
      } else {
        send_payload(event_id, student_id, form_payload)
      }

      function send_payload(event_id, student_id, payload) {
        $.ajax({
          url: '/events/' + event_id + '/attend/' + student_id,
          type: 'PATCH',
          data: payload
        }).done( function() {})
      }
    })

    $('.toggle-number-update').on('click', function(e) {
      var student_id =$('#sms-pass-account input#student_id').val()

      // make sure the student id is passed
      $('#send-update-link').attr('data-student_id', student_id)
      target_wrapper = $('.new-phone-number')
      if (target_wrapper.hasClass('d-none')) {
        target_wrapper.removeClass('d-none')
      } else {
        target_wrapper.addClass('d-none')
      }
    })

    $('.send-sms-pass-item').on('click', function(e) {
      var ref_id = $(this).data('id')

      $('.send-sms-pass-item').each( function(i) {
        if (($(this).data('id') != ref_id) && ($(this).is(':checked'))) {
          $(this).prop('checked', false)
        }
      })
    })
  }
}

document.addEventListener("turbolinks:load", () => {
  if ($('#send-pass-via-sms') || $('.adds-student')[0]) {
    SendPassViaSMS.init()
  }
})
