var Dashboard = {
  init: function() {
    this.graph_membership_by_grade()
    this.graph_attendance_by_grade()
    this.graph_attendance_by_dojo()
    this.graph_attendance_by_instructor()
    this.graph_attendance_by_day()
    this.graph_growth_and_decline()
  },
  graph_membership_by_grade: function() {
    var ctx = document.getElementById('membership-breakdown').getContext('2d')
    var byGrade = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: JSON.parse(ctx.canvas.dataset.bg),
          borderColor: JSON.parse(ctx.canvas.dataset.br),
          borderWidth: 1,
          borderRadius: 5
        }]
      },
      options: {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    })
  },
  graph_attendance_by_grade: function() {
    var ctx = document.getElementById('attendance-breakdown-grade').getContext('2d')
    var byGrade = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: JSON.parse(ctx.canvas.dataset.bg),
          borderColor: JSON.parse(ctx.canvas.dataset.br),
          borderWidth: 1,
          borderRadius: 5
        }]
      },
      options: {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    })
  },
  graph_attendance_by_dojo: function() {
    var ctx = document.getElementById('attendance-breakdown-dojo').getContext('2d')
    var byGrade = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          borderWidth: 1,
          borderRadius: 5
        }]
      },
      options: {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          autocolors: {
            mode: 'data'
          }
        }
      },
    })
  },
  graph_attendance_by_instructor: function() {
    var ctx = document.getElementById('attendance-breakdown-instructor').getContext('2d')
    var byGrade = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          borderWidth: 1,
          borderRadius: 5
        }]
      },
      options: {
        maintainAspectRatio: false,
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          autocolors: {
            mode: 'data'
          }
        }
      },
    })
  },
  graph_attendance_by_day: function() {
    var ctx = document.getElementById('attendance-breakdown-day').getContext('2d')
    var byGrade = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          borderWidth: 1,
          borderRadius: 5
        }]
      },
      options: {
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          autocolors: {
            mode: 'data'
          }
        }
      },
    })
  },
  graph_growth_and_decline: function() {
    var ctx = document.getElementById('growth-decline').getContext('2d')
    var byGrade = new Chart(ctx, {
      type: 'bar',
      data: {
        spanGaps: true,
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [
          {
            label: 'Activated',
            data: JSON.parse(ctx.canvas.dataset.growth),
            backgroundColor: 'rgb(202, 12, 35, 0.9)',
            borderColor: 'rgb(202, 12, 35, 1)',
            borderWidth: 1,
            borderRadius: 5,
            borderSkipped: false,
            order: 2
          },
          {
            label: 'Deactivated',
            data: JSON.parse(ctx.canvas.dataset.decline),
            backgroundColor: 'rgb(0, 0, 0, 0.9)',
            borderColor: 'rgb(0, 0, 0, 1)',
            borderWidth: 1,
            borderRadius: 5,
            borderSkipped: false,
            order: 1
          },
          {
            label: 'Active DD',
            data: zeroToNull(JSON.parse(ctx.canvas.dataset.activedd)),
            backgroundColor: 'rgb(249, 217, 73, 0.5)',
            borderColor: 'rgb(249, 217, 73, 1)',
            type: 'line',
            yAxisID: 'y1',
            order: 0
          }
        ]
      },
      options: {
        scales: {
          x: {
            stacked: true,
            position: 'bottom'
          },
          y: {
            beginAtZero: true
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            }
          }
        },
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          autocolors: {
            mode: 'data'
          }
        }
      },
    })

    function zeroToNull(array) {
      return array.map(function(v) {
        if (v==0) return null; else return v;
      })
    }
  }
}

document.addEventListener("turbolinks:load", () => {
  if ($('.graphs')[0]) {
    Dashboard.init();
  }
});

window.initDashboard = function() {
  Dashboard.init();
}
