document.addEventListener("turbolinks:load", () => {

  $(document).on('click', '#copy-payment-link', function(event) {
    var paymentLink = document.getElementById("contract-payment-link")

    paymentLink.select()
    paymentLink.setSelectionRange(0, 99999) // For mobile devices

    navigator.clipboard.writeText(paymentLink.value)

    $('#link-notif').text('Payment link copied to clipboard.')
  })
})
