var StudentForm = {
  init: function() {
    this.assign_event_handlers();
    this.init_main_contact_lookup_autocomplete();
  },
  assign_event_handlers: function() {
    $(".admin-student-details-form input[name='student[use_existing_main_contact]']").change(function() {
      StudentForm.toggle_use_existing_main_contact();
    }).trigger('change');

    $(".admin-student-details-form input[name='student[main_contact_lookup]']").keyup(function() {
      this.value = this.value.replace(/\s/g, '');
    });

    $(".admin-student-details-form").on('change', "input[name*='birth_date']", function() {
      StudentForm.check_birth_date_validity($(this));
    });

    $(".admin-student-details-form button#add-another-student-button").click(function() {
      StudentForm.add_another_student();
    });
  },
  init_main_contact_lookup_autocomplete: function() {
    $(".admin-student-details-form input[name='student[main_contact_lookup]']").autocomplete({
      serviceUrl: '/admin/students',
      dataType: 'json',
      minChars: 3,
      formatResult: function(suggestion, currentValue) {
        return suggestion.value.replace(currentValue, ('<strong>' + currentValue + '</strong>'));
      },
      onSelect: function(suggestion) {
        StudentForm.populate_from_autocomplete_data(suggestion.data);
      }
    });
  },
  toggle_use_existing_main_contact: function() {
    var $use_existing_main_contact = $(".admin-student-details-form input[type='checkbox'][name='student[use_existing_main_contact]']");
    var $main_contact_lookup = $(".admin-student-details-form input[name='student[main_contact_lookup]']");
    var $main_contact_name = $(".admin-student-details-form input[name='student[main_contact_name]']");

    if ($use_existing_main_contact[0].checked) {
      // Search for and use an existing main contact.
      $main_contact_lookup.prop('disabled', false);
      $main_contact_name.prop('disabled', true);

      $main_contact_lookup.focus();
    }
    else {
      // Create a new main contact.
      $main_contact_lookup.prop('disabled', true);
      $main_contact_name.prop('disabled', false);

      $main_contact_name.focus();
    }
  },
  populate_from_autocomplete_data: function(data) {
    $(".admin-student-details-form input[name='student[main_contact_sfid]']").val(data.main_contact_sfid);
    $(".admin-student-details-form input[name='student[mobile_phone]']").val(data.mobile_phone);
    $(".admin-student-details-form input[name='student[email]']").val(data.email);
    $(".admin-student-details-form input[name='student[street_address]']").val(data.street_address);
    $(".admin-student-details-form input[name='student[city_suburb]']").val(data.city_suburb);
    $(".admin-student-details-form input[name='student[state]']").val(data.state);
    $(".admin-student-details-form input[name='student[postcode]']").val(data.postcode);
    $(".admin-student-details-form select[name='student[country]']").val(data.country);
  },
  check_birth_date_validity: function($birth_date) {
    $birth_date_warning = $birth_date.siblings('.invalid-feedback');

    var selected_birth_date = Date.parse($birth_date.val());

    var now = new Date();
    var three_years_ago = now.setFullYear(now.getFullYear() - 3);

    if (selected_birth_date > three_years_ago) {
      $birth_date_warning.show().text('Is this date correct? Is the student less than 3 years old?');
    }
    else {
      $birth_date_warning.hide()
    }
  },
  add_another_student: function() {
    var student_details_fields_count = $(".admin-student-details-form #cloneable-student-details-fields").length;

    var $cloneable_student_details_fields = $(".admin-student-details-form #cloneable-student-details-fields:last");

    var cloneable_student_details_id = $cloneable_student_details_fields.find("input[type='hidden']").val();
    var cloned_student_details_id = 'new-student-details-' + (student_details_fields_count + 1);

    // Make a new copy of the div of fields.
    var $cloned_new_student_details_fields = $cloneable_student_details_fields.clone(true);

    // Replace the ID (used in input names, ids, etc) with the new one.
    $cloned_new_student_details_fields.html($cloned_new_student_details_fields.html().replace(new RegExp(cloneable_student_details_id, 'g'), cloned_student_details_id));

    // Set the header.
    $cloned_new_student_details_fields.find('h2').text('Student Details (Student #' + (student_details_fields_count + 1) + ')');

    // Clear inputs (except for the dropdown list, and the hidden one which holds the ID).
    $cloned_new_student_details_fields.find('input').not("[type='hidden']").not("[type='radio']").val('');
    $cloned_new_student_details_fields.find("input[type='radio']").prop('checked', false);
    $cloned_new_student_details_fields.find('.invalid-feedback').hide();

    // Show and initialize remove button
    $cloned_new_student_details_fields
      .find('#remove-student-button')
      .show()
      .on('click', function(e) {
        StudentForm.remove_student(e);
      });

    $cloned_new_student_details_fields.insertAfter($cloneable_student_details_fields);
  },
  remove_student: function(e) {
    e.target.parentElement.remove();
  }
};

document.addEventListener("turbolinks:load", () => {
  if ($('.admin-student-details-form')[0]) {
    StudentForm.init();
  }
});
