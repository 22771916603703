document.addEventListener("turbolinks:load", () => {

  $(document).on('click', '#contract-credit-card', function(event) {
    let student_id = $(this).data("id")
    $.ajax({
      url: '/admin/students/' + student_id + '/reprocess_cc',
      type: 'GET',
      dataType: 'script'
    })
  })
})
