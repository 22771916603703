var AdministratorForm = {
  init: function() {
    this.assign_event_handlers();
  },
  assign_event_handlers: function() {
    $(".admin-administrator-form select[name='administrator[role_name]']").change(function() {
      AdministratorForm.toggle_role_scope_fields(this.value);
    }).trigger('change');

    $(".admin-administrator-form select[name='administrator[role_scope_country]']").change(function() {
      var admin_ref = $('#admin-country-options').data('reference')
      AdministratorForm.load_country_regions(this.value, admin_ref);
    }).trigger('change');

    $('.admin-administrator-form').submit(function() {
      AdministratorForm.enable_disabled_fields_for_submission();
    });

    AdministratorForm.role_select()
  },
  toggle_role_scope_fields: function(role_name) {
    var $role_scope_country_field = $(".admin-administrator-form select[name='administrator[role_scope_country]']");
    var $role_scope_zone_field = $(".admin-administrator-form select[name='administrator[role_scope_zone]']");
    var $role_scope_region_field = $(".admin-administrator-form select[name='administrator[role_scope_region]']");
    var $region_select_wrapper = $('.region-select-wrapper');
    var with_other_regions = ['membership_consultant', 'regional_manager']

    switch(role_name) {
    case '':
      $role_scope_country_field.prop('disabled', true).val('');
      $role_scope_zone_field.prop('disabled', true).val('');
      $role_scope_region_field.prop('disabled', true).val('');
      break;
    case 'international_admin':
      $role_scope_country_field.prop('disabled', true).val('');
      $role_scope_zone_field.prop('disabled', true).val('');
      $role_scope_region_field.prop('disabled', true).val('');
      break;
    case 'national_admin':
      $role_scope_country_field.prop('disabled', false);
      $role_scope_zone_field.prop('disabled', true).val('');
      $role_scope_region_field.prop('disabled', true).val('');
      break;
    case 'zone_director':
      $role_scope_country_field.prop('disabled', false);
      $role_scope_zone_field.prop('disabled', false);
      $role_scope_region_field.prop('disabled', true).val('');
      break;
    case 'regional_manager':
      $role_scope_country_field.prop('disabled', false);
      $role_scope_zone_field.prop('disabled', false);
      $role_scope_region_field.prop('disabled', false);
      break;
    case 'membership_consultant':
      $role_scope_country_field.prop('disabled', false);
      $role_scope_zone_field.prop('disabled', false);
      $role_scope_region_field.prop('disabled', false);
      break;
    }

    if (with_other_regions.includes(role_name)) {
      if ($region_select_wrapper.hasClass('hidden')) {
        $region_select_wrapper.removeClass('hidden')
        AdministratorForm.role_select()
      }
    } else {
      if (!$region_select_wrapper.hasClass('hidden')) {
        $region_select_wrapper.addClass('hidden')
      }
    }
  },
  load_country_regions: function(country, admin_ref) {
    $.ajax({
      method: 'GET',
      url: '/admin/administrators/regions',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: {
        country: country,
        id: admin_ref
      },
      success: function(data) {
        AdministratorForm.role_select()
      }
    })
  },
  role_select: function() {
    $('.role-select').select2({
      allowClear: true,
      placeholder: 'Select other Regions',
      width: '100%',
      tags: true,
      tokenSeparators: [',']
    })
  },
  enable_disabled_fields_for_submission: function() {
    // To ensure that all fields are submitted to the controller.
    $('.admin-administrator-form').find('input, select').prop('disabled', false);
  }
};

document.addEventListener("turbolinks:load", () => {
  if ($('.admin-administrator-form')[0]) {
    AdministratorForm.init();
  }
});
